<template>
  <div>
      <div class="card card-shadow">
          <div class="card-inner">
              <div class="row">
                  <div class="col-12" v-if="tickets">
                    <vue-good-table v-if="tickets"
                        :columns="columns"
                        :rows="tickets"
                        styleClass="vgt-table condensed tblhov"
                        :search-options="{ enabled: true, placeholder: 'Search Tickets'}"
                        :sort-options="{ enabled: true, field: 'id', type:'desc'}"
                        @on-cell-click="showTicket"
                        :pagination-options="{
                          enabled: true,
                          mode: 'records',
                          perPage: 50,
                          position: 'bottom',
                          perPageDropdown: [50,100,150],
                          dropdownAllowAll: false,
                          setCurrentPage: 1,
                          nextLabel: 'Next',
                          prevLabel: 'Prev',
                          rowsPerPageLabel: 'Rows per page',
                          ofLabel: 'of',
                          pageLabel: 'page', // for 'pages' mode
                          allLabel: 'All',
                        }"
                    >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'createdDate'">{{ $moment(props.row.createdDate).format("H:mm a DD/MM/YYYY") }}</span>
                        <span v-else-if="props.column.field == 'completedDate' && props.row.completedDate">{{ $moment(props.row.completedDate).format("H:mm a DD/MM/YYYY") }}</span>
                        <span v-else-if="props.column.field == 'completedDate' && !props.row.completedDate">Open</span>
                    </template>
                    </vue-good-table>
                </div>
              </div>
          </div>
      </div>
      <a-drawer :width="600"
        placement="right"
        :closable="false"
        @close="closeTicket()"
        :visible="showTicketData">
        <ticket :ticket="selectedTicket" />
      </a-drawer> 
  </div>
</template>

<script>
import ticket from '@/components/desk/tickets/ticket.vue'

export default {
    props: {
        searchTerm: String,
        ticketId: Number
    },
    components: { ticket },
    data() {
        return {
            showTicketData: false,
            tickets: [],
            accounts: [],
            priorities: [],
            columns: [
            {
              label: 'Ticket No',
              align: 'start',
              field: 'id',
              type: 'number'
            },
            {
              label: 'Issue',
              sortable: false,
              field: 'title',
            }, {
              label: 'Contact Name',
              sortable: false,
              field: 'contactName'
            }, {
              label: 'Priority',
              sortable: false,
              field: 'priority'
            },{
              label: 'Issue Type',
              sortable: false,
              field: 'issueType'
            }, {
              label: 'Status',
              sortable: false,
              field: 'status'
            }, {
              label: 'Created Date',
              field: 'createdDate'
            }, {
              label: 'Completed Date',
              field: 'completedDate'
            }],
            selectedTicket: {},
        }
    },
    created() {
        if (this.ticketId) {
            this.getTicket()
        }
        this.getTicketsFromSearch()
        this.getLists()
    },
    methods: {
        getTicketsFromSearch() {
            this.$http.get('/search/Get_TicketsFromSearch/' + this.searchTerm)
            .then((response) => {
                this.tickets = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        showTicket(params) {
          this.selectedTicket = params.row
          this.showTicketData = true
        },
        getTicket() {
            this.$http.get('/desk/Get_Ticket/' + this.ticketId)
            .then((response) => {
                this.selectedTicket = response.data
                this.showTicketData = true
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getLists(){
          this.$http.get('/lists/Get_List/accounts')
          .then((response) => {
              this.accounts = response.data
          })
          .catch(() => {
          this.$message.error('There has been an error')
          })
          this.$http.get('/lists/Get_List/ticketPriorities')
          .then((response) => {
              this.priorities = response.data
          })
          .catch(() => {
          this.$message.error('There has been an error')
          })
        },
        closeTicket(){
          this.selectedTicket = null
          this.showTicketData = false
        },
    }
}
</script>

<style>

</style>