<template>
  <div>
      <div class="card card-shadow">
          <div class="card-inner">
              <div class="row">
                  <div class="col-12" v-if="accounts">
                    <vue-good-table v-if="accounts"
                        :columns="columns"
                        :rows="accounts"
                        styleClass="vgt-table condensed tblhov"
                        :search-options="{ enabled: true, placeholder: 'Search Accounts'}"
                        :sort-options="{ enabled: true, field: 'id', type:'desc'}"
                        @on-cell-click="goToAccount"
                        :pagination-options="{
                          enabled: true,
                          mode: 'records',
                          perPage: 50,
                          position: 'bottom',
                          perPageDropdown: [50,100,150],
                          dropdownAllowAll: false,
                          setCurrentPage: 1,
                          nextLabel: 'Next',
                          prevLabel: 'Prev',
                          rowsPerPageLabel: 'Rows per page',
                          ofLabel: 'of',
                          pageLabel: 'page', // for 'pages' mode
                          allLabel: 'All',
                        }"
                    >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'createdDate'">{{ $moment.utc(props.row.createdDate).local().format("H:mm a DD/MM/YYYY") }}</span>
                    </template>
                    </vue-good-table>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

export default {
    props: {
        searchTerm: String
    },
    data() {
        return {
            accounts: [],
            columns: [
            {
              label: 'Account No',
              align: 'start',
              field: 'id',
              type: 'number'
            },
            {
              label: 'Name',
              sortable: false,
              field: 'accountName',
            },{
              label: 'Phone Number',
              sortable: false,
              field: 'phoneNumber',
            },{
              label: 'Account Owner',
              sortable: false,
              field: 'accountOwner',
            },{
              label: 'Billing Contact',
              sortable: false,
              field: 'billingContact',
            }, {
              label: 'Created Date',
              field: 'createdDate'
            }],
        }
    },
    created() {
        this.getAccountsFromSearch()
    },
    methods: {
        getAccountsFromSearch() {
            this.$http.get('/search/Get_AccountsFromSearch/' + this.searchTerm)
            .then((response) => {
                this.accounts = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        goToAccount(params) {
          this.selectedTicket = params.row
        },
    }
}
</script>

<style>

</style>