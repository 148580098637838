<template>
  <div>
      <h4 class="title">Search Results For {{$route.query.entityName}}</h4>
      <tickets :searchTerm="searchTerm" :ticketId="ticketId" v-if="$route.query.entityName == 'Tickets' || $route.query.entityName == 'Ticket'"/>
      <accounts :searchTerm="searchTerm" v-if="$route.query.entityName == 'Accounts'"/>
      <contacts :searchTerm="searchTerm" v-if="$route.query.entityName == 'Contacts'"/>
  </div>
</template>

<script>
import tickets from '@/components/search/tickets.vue'
import accounts from '@/components/search/accounts.vue'
import contacts from '@/components/search/contacts.vue'

export default {
  props: {
    searchTerm: String,
    ticketId: Number,
  },
  components: {
    tickets,
    accounts,
    contacts
  },
  methods:{
    getView (){ this.$http.get('/ViewAccess/search')
        .then(() => { 
        })
        .catch(() => { 
        })},
  },
  created() {
    this.getView()
  }
}
</script>

<style>

</style>