var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card card-shadow"},[_c('div',{staticClass:"card-inner"},[_c('div',{staticClass:"row"},[(_vm.accounts)?_c('div',{staticClass:"col-12"},[(_vm.accounts)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.accounts,"styleClass":"vgt-table condensed tblhov","search-options":{ enabled: true, placeholder: 'Search Accounts'},"sort-options":{ enabled: true, field: 'id', type:'desc'},"pagination-options":{
                        enabled: true,
                        mode: 'records',
                        perPage: 50,
                        position: 'bottom',
                        perPageDropdown: [50,100,150],
                        dropdownAllowAll: false,
                        setCurrentPage: 1,
                        nextLabel: 'Next',
                        prevLabel: 'Prev',
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                      }},on:{"on-cell-click":_vm.goToAccount},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'createdDate')?_c('span',[_vm._v(_vm._s(_vm.$moment.utc(props.row.createdDate).local().format("H:mm a DD/MM/YYYY")))]):_vm._e()]}}],null,false,2362532245)}):_vm._e()],1):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }